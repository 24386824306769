import Divider from '@solid-ui-components/Divider'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Footer from '../../../blocks/Footer/Block01'

import Header from '@solid-ui-blocks/Header/Block01'

import Layout from '../../../@elegantstack/solid-ui-layout/src/Layout/Layout'

import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import React from 'react'
import Seo from '@solid-ui-components/Seo'

import theme from './_theme'

import {graphql} from 'gatsby'
import {normalizeBlockContentNodes} from '@blocks-helpers'
import {Container} from "theme-ui";
import styles from "../intel/_styles";

const IndexPage = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout theme={theme} {...props}>
            <Seo title='Deepclean'/>
            {/* Modals */}
            <ModalWithTabs content={content['contact-en']}/>
            {/* Blocks */}
            <Header content={content['header-light-en']}/>
             <Container title='' variant='full' sx={styles.heroContainer}>
                <Hero content={content['hero']} reverse/>
            </Container>
            <Footer content={content['footer-en']}/>
        </Layout>
    )
}

export const query = graphql`
  query innerpageSiteDeepcleanBlockContentEn {
    allBlockContent(
      filter: { page: { in: ["site/deepclean-en", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
